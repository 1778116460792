<script setup>
import { useProjectService } from "@/components/ExternalApps/WatchDog/composables/useProjectService";
import Card from "@/components/Tools/Card/Card.vue";
import FormHelper from "@/components/Tools/FormHelper/FormHelper.vue";
import { onMounted, ref } from "vue";
import Header from "@/components/Tools/Header/Header.vue";
import {
  addEventToLoadingQueue,
  removeEventFromLoadingQueue
} from "@/composables/useLoadingQueue";
import Presets from "@/components/Settings/Presets/presets";
import { useStore } from "@/core/services/store";
import { UPDATE_SELECTED_PROJECT } from "@/core/services/store/switchArea.module";
import i18n from "@/core/plugins/vue-i18n";
import { useWatchDogFormService } from "@/components/ExternalApps/WatchDog/composables/useWatchDogFormService";
import Tabs from "@/components/Tools/Tabs.vue";
import { formConfig } from "@/components/ExternalApps/WatchDog/config/general";

const store = useStore();
const { project } = useProjectService();
const { formsConfigurationFieldNames, formsConfiguration } =
  useWatchDogFormService();
const model = ref({});
const presets = ref([]);
const valuesToSave = ref([]);

const headerItems = [
  {
    type: "button",
    title: i18n.t("general.save"),
    emit: "save",
    icon: "floppy-disk"
  }
];
const tabItems = [
  {
    key: "salesOrders"
  }
];

let selectedTab = ref(tabItems[0].key);

function selectTab(value) {
  selectedTab.value = value;
}

function transformValues() {
  // Reset valuesToSave
  valuesToSave.value = [];
  for (const [name, value] of Object.entries(model.value)) {
    let field = getField(name);
    if (!field) continue;

    field.value = value;
    valuesToSave.value.push(field);
  }
}

function getField(name) {
  let presetField = presets.value?.find(field => field.name === name);

  if (presetField) return presetField;

  let field = { ...formsConfiguration.value }.find(
    field => field.name === name
  );
  if (!field) return;

  return {
    label: field.label,
    name: field.name,
    namespace: field.name,
    type: field.type !== "text" || field.type !== "number" ? "text" : field.type
  };
}

function transformModel() {
  for (const item of presets.value) {
    model.value[item.name] = item.value;
  }
}

function storePreset(preset, index) {
  preset.project_id = project.value.id;
  addEventToLoadingQueue({ key: "storePresetValues" + index });

  Presets.store(preset, true).finally(() =>
    removeEventFromLoadingQueue({ key: "storePresetValues" + index })
  );
}

function updatePreset(preset) {
  addEventToLoadingQueue({ key: "storePresetValues" + preset.id });
  Presets.update(preset.id, preset, true).finally(() =>
    removeEventFromLoadingQueue({ key: "storePresetValues" + preset.id })
  );
}

function onSubmit() {
  // Transform model values
  transformValues();
  // Saves presets
  for (const [i, preset] of Object.entries(valuesToSave.value)) {
    if (preset?.id) {
      updatePreset(preset);
    } else {
      storePreset(preset, i);
    }
  }
}

function getPresets() {
  addEventToLoadingQueue({ key: "getPresets" });

  const filter = [
    {
      key: "name",
      op: "in",
      value: formsConfigurationFieldNames.value
    }
  ];

  Presets.getAll({ noPagination: true }, filter).then(response => {
    presets.value = response.data;
    removeEventFromLoadingQueue({ key: "getPresets" });

    if (presets.value?.length > 0) {
      transformModel();
    }
  });
}

onMounted(async () => {
  await store.dispatch(UPDATE_SELECTED_PROJECT, project.value);
  getPresets();
});
</script>

<template>
  <div>
    <Header
      :items="headerItems"
      :show-back-button="true"
      :title="$t('watchDog.configuration') + ' ' + project.name"
      @back="$router.push({ name: 'watchDogProjects' })"
      @save="onSubmit"
    />
    <Card class="pa-6">
      <Tabs
        :items="tabItems"
        translate-prefix="watchDog.titles"
        @on-select-tab="selectTab"
      >
        <template v-if="selectedTab === 'salesOrders'">
          <div
            v-for="(form, i) in formsConfiguration[selectedTab]"
            :key="i"
            :class="{ 'mt-6': i !== 0 }"
          >
            <FormHelper
              v-model="model"
              :config="{
                ...{ title: i18n.t('watchDog.configurations.' + i) },
                ...formConfig
              }"
              :form="form"
            ></FormHelper>
          </div>
        </template>
      </Tabs>
    </Card>
  </div>
</template>

<style lang="scss" scoped></style>
